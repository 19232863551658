import { GetStaticProps } from "next";
import styled from "styled-components";
import { mainLayoutQuery } from "../lib/sanity/queries";
import { getClient } from "../lib/sanity/server";
import { H1, H2 } from "../lib/styles/text";

type Props = {
  settings: any;
};

export const getStaticProps: GetStaticProps<Props> = async ({
  locale,
  defaultLocale,
}) => {
  // Get site settings
  const settings = await getClient(false).fetch(mainLayoutQuery, {
    locale,
    defaultLocale,
  });

  return {
    props: {
      settings,
    },
    revalidate: 60,
  };
};

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
`;

const Custom404 = () => {
  return (
    <Container>
      <H2 as="h1">404 - Not Found</H2>
    </Container>
  );
};

export default Custom404;
